import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, isRef as _isRef } from "vue"

const _hoisted_1 = { id: "searchCenter" }
const _hoisted_2 = { class: "standard_tip" }
const _hoisted_3 = { key: 0 }

import CommonSearch from '@/components/CommonSearch.vue'
  import CommonTable from '@/components/CommonTable.vue'
  import CommonPagination from '@/components/CommonPagination.vue'
  import AddApplyPanel from '@/components/AddApplyPanel.vue';
  import { CONST } from '@/types/const'
  import { ref, watch, getCurrentInstance, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      CommonSearch,
      CommonPagination,
      CommonTable,
      AddApplyPanel
    }
  },
  __name: 'Index',
  setup(__props) {

  

  let totalNum = ref(100)
  let pageNum = ref(0)
  let pageSize = ref(10)
  let checked = ref(false);
  let checkedList = ref([]);
  let clearC = ref(false);
  let clearS = ref(true);
  let indeterminate = ref(false);
  let tableData = ref([])
  let searchData = ref({})
  let searchStatus = ref(false)
  const route = useRoute()
  const router = useRouter()
  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;

  onMounted(() => {
    if(route.name != 'batchImport' || ((route.name as any) == 'searchCenter' && !route.query.keyword)) showPage();
  })

  // watch(() => route.name, (n, o) => {
  //   if(n && n != o) {
  //     if(route.name != 'batchImport') searchClick({})
  //   }
  // })


  function changeSelect() {
    indeterminate.value = false;
  }

  function checkChange(data: any) {
    if(data.type == 2) {
      checked.value = true
      indeterminate.value = false
    }else if(data.type == 1) {
      checked.value = false
      indeterminate.value = true
    }else {
      checked.value = false
      indeterminate.value = false
    }
    checkedList.value = data.data
  }

  function showPage() {
    let defaultClassifications;
    if (route.name === 'searchCenter') {
        defaultClassifications = ["国家标准","行业标准","团体标准","其他标准","地方标准"] ; 
    } else if (route.name === 'standardDynamics') {
        defaultClassifications = ["国家标准","行业标准","团体标准","其他标准"] ; 
    } else {
        defaultClassifications = ["国家标准","行业标准","团体标准","地方标准","国际标准","国外标准","其他标准"]; 
    }
    globalProperties.$API.StandardList({
      pageNum: pageNum.value,
      pageSize: pageSize.value,
      standardName: (searchData.value as any).standardName || '',
      standardNumber: (searchData.value as any).standardNumber || '',
      standardStateList: (searchData.value as any).standardStateList && (searchData.value as any).standardStateList != 'ALL' ? [(searchData.value as any).standardStateList] : [] ,
      standardClassificationList: (searchData.value as any).standardClassificationList  && (searchData.value as any).standardClassificationList != 'ALL' ? [(searchData.value as any).standardClassificationList] : defaultClassifications ,
      listProperties: (searchData.value as any).listProperties  && (searchData.value as any).listProperties != 'ALL' ? [(searchData.value as any).listProperties] : [] ,
      startImplementDate: (searchData.value as any).startImplementDate || '',
      endImplementDate: (searchData.value as any).endImplementDate || '',
      startReleaseDate: (searchData.value as any).startReleaseDate || '',
      endReleaseDate: (searchData.value as any).endReleaseDate || '',
      sort: parseInt((searchData.value as any).sort)
    }).then((res: any) => {
      if(res.data.code == 200) {
        if(!res.data.data) {
          totalNum.value = 0
          tableData.value = []
        }else {
          res.data.data.results.forEach((item: any) => {
            let data = (CONST as any).standardState.filter((line: any) => { 
              return line.value ==  item.standard_state 
            })
            item.statusName = data[0].label;
            item.color = data[0].color;
            item.chinese_name = item.chinese_name || item.english_name
            item.release_date = item.release_date ? item.release_date.split(' ')[0] : ''
            item.implement_date = item.implement_date ?  item.implement_date.split(' ')[0] : ''
          })
          totalNum.value = res.data.data.totalResult
          tableData.value = res.data.data.results
          clearS.value = false;
        }
        
      }
    })
  }

  function searchClick(data: any) {
    searchData.value = { ...data };
    pageNum.value = 0;
    showPage();
  }  

  function optionClick(list: object | null) {
    if(!list && checkedList.value.length == 0) return globalProperties.$message.warning('请选择要收藏的标准！');
    globalProperties.$API.GetUserInfo().then((res1: any) => {
      if(res1.data.code == 200) {
        clearC.value = false;
        let ids = list ? [list] : checkedList.value;
        globalProperties.$API.CollectStandard({
          listStandardNumber: ids
        }).then((res: any) => {
          if(res.data.code == 200) {
            indeterminate.value = false;
            checked.value = false;
            clearC.value = true;
            globalProperties.$message.success('操作成功');
          }else {
            globalProperties.$message.error(res.data.msg || '操作失败');
          }
        })
      }else {
        globalProperties.$message.warning('您还未登录或者登录已失效，请先登录再操作此按钮！')
      }
    })
    
  }

  function importSubmitEvent(data: any) {
    const formData1 = new FormData();
    formData1.append('file', data.file9);
    globalProperties.$API.ImportList(formData1).then((res: any) => {
      if(res.data.code == 200) {
        res.data.data.forEach((item: any) => {
          let data = (CONST as any).standardState.filter((line: any) => { 
            return line.value ==  item.standardState 
          })
          item.statusName = data[0].label;
          item.color = data[0].color;
          item.standard_number = item.standardNumber
          item.chinese_name = item.chineseName || item.englishName
          item.release_date = item.releaseDate ? item.releaseDate.split(' ')[0] : ''
          item.implement_date = item.implementDate ?  item.implementDate.split(' ')[0] : ''
        })
        tableData.value = res.data.data;
        searchStatus.value = true;
      }
    })
  }



return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.meta.search)
      ? (_openBlock(), _createBlock(CommonSearch, {
          key: 0,
          searchData: (_unref(CONST) as any)[(_ctx.$route.meta.search as any)],
          clearS: _unref(clearS),
          onSearch: searchClick
        }, null, 8, ["searchData", "clearS"]))
      : (_openBlock(), _createBlock(AddApplyPanel, {
          key: 1,
          panel: "batchImport",
          onSubmit: importSubmitEvent
        })),
    (_ctx.$route.name != 'batchImport' || (_ctx.$route.name == 'batchImport' && _unref(searchStatus)))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (optionClick(null)))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("一键收藏")
                ])),
                _: 1
              }),
              (_ctx.$route.name != 'batchImport')
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/batchImport')))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("批量导入")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.$route.name != 'batchImport')
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, "共找到 " + _toDisplayString(_unref(totalNum)) + " 条记录", 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(CommonTable, {
            checked: _unref(checked),
            columns: (_unref(CONST) as any).standardTable,
            tableData: _unref(tableData),
            select: true,
            clearC: _unref(clearC),
            indeterminate: _unref(indeterminate),
            onCheckChange: checkChange
          }, {
            options: _withCtx(({ record }) => [
              _createVNode(_component_a_button, {
                type: "text",
                onClick: ($event: any) => (optionClick(record.standard_number))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("收藏")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["checked", "columns", "tableData", "clearC", "indeterminate"])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.$route.name != 'batchImport')
      ? (_openBlock(), _createBlock(CommonPagination, {
          key: 3,
          pageNum: _unref(pageNum) + 1,
          pageSize: _unref(pageSize),
          totalNum: _unref(totalNum),
          onPageChange: _cache[4] || (_cache[4] = (page) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = page - 1 : pageNum = page - 1; showPage() }),
          onSizeChange: _cache[5] || (_cache[5] = (size) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = 0 : pageNum = 0; _isRef(pageSize) //@ts-ignore
 ? pageSize.value = size : pageSize = size; showPage() })
        }, {
          left: _withCtx(() => [
            _createVNode(_component_a_checkbox, {
              modelValue: _unref(checked),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(checked) ? (checked).value = $event : checked = $event)),
              indeterminate: _unref(indeterminate),
              onChange: changeSelect
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("当页全选")
              ])),
              _: 1
            }, 8, ["modelValue", "indeterminate"]),
            _createVNode(_component_a_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (optionClick(null)))
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("一键收藏")
              ])),
              _: 1
            })
          ]),
          prefix: _withCtx(() => [
            _createTextVNode(" 共" + _toDisplayString(_unref(totalNum)) + "条，每页", 1)
          ]),
          _: 1
        }, 8, ["pageNum", "pageSize", "totalNum"]))
      : _createCommentVNode("", true)
  ]))
}
}

})